// Ordered by app actions -> conviction, dandelion voting, agreement, staking rather than alphabetically
export enum GardenActionTypes {
  NEW_PROPOSAL = 'NEW_PROPOSAL',
  NEW_SIGNALING_PROPOSAL = 'NEW_SIGNALING_PROPOSAL',
  CANCEL_PROPOSAL = 'CANCEL_PROPOSAL',
  STAKE_TO_PROPOSAL = 'STAKE_TO_PROPOSAL',
  WITHDRAW_FROM_PROPOSAL = 'WITHDRAW_FROM_PROPOSAL',
  EXECUTE_PROPOSAL = 'EXECUTE_PROPOSAL',
  EXECUTE_ADJUSTMENT = 'EXECUTE_ADJUSTMENT',
  NEW_DECISION = 'NEW_DECISION',
  VOTE_ON_DECISION = 'VOTE_ON_DECISION',
  VOTE_ON_BEHALF_OF = 'VOTE_ON_BEHALF_OF',
  EXECUTE_DECISION = 'EXECUTE_DECISION',
  DELEGATE_VOTING = 'DELEGATE_VOTING',
  SIGN_AGREEMENT = 'SIGN_AGREEMENT',
  CHALLENGE_ACTION = 'CHALLENGE_ACTION',
  APPROVE_TOKEN = 'APPROVE_TOKEN',
  RESOLVE_ACTION = 'RESOLVE_ACTION',
  SETTLE_ACTION = 'SETTLE_ACTION',
  DISPUTE_ACTION = 'DISPUTE_ACTION',
  WRAP_TOKEN = 'WRAP_TOKEN',
  UNWRAP_TOKEN = 'UNWRAP_TOKEN',
  CLAIM_REWARDS = 'CLAIM_REWARDS',
  ADD_FUNDS = 'ADD_FUNDS',
  WITHDRAW_FUNDS = 'WITHDRAW_FUNDS',
  ALLOW_MANAGER = 'ALLOW_MANAGER',
  UPDATE_PRICE_ORACLE = 'UPDATE_PRICE_ORACLE',
  ACTIVATE_STREAM_PROPOSAL = 'ACTIVATE_STREAM_PROPOSAL',
  REGISTER_STREAM_PROPOSAL = 'REGISTER_STREAM_PROPOSAL',
}
